import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import {
  TRACKING_CATEGORIES,
  TRACKING_EVENTS,
  logStatsig,
} from "shared-lib/helpers/statsigFunctions";

const wk_metadata = {
  adType: "overlay",
};

export const wkAccepted = () => {
  // console.log("wk_accepted TRIGGERED!!!!");
  logStatsig(TRACKING_EVENTS.wk_accepted, wk_metadata);
  ClickServer.track(TRACKING_EVENTS.wk_accepted, wk_metadata);
  gtag.event({
    action: TRACKING_EVENTS.wk_accepted,
    category: TRACKING_CATEGORIES.interaction,
    label: "overlay",
  });
};

export const wkClick = () => {
  // console.log("wk_clicked TRIGGERED!!!!");
  logStatsig(TRACKING_EVENTS.wk_clicked, wk_metadata);
  ClickServer.track(TRACKING_EVENTS.wk_clicked, wk_metadata);
  gtag.event({
    action: TRACKING_EVENTS.wk_clicked,
    category: TRACKING_CATEGORIES.interaction,
    label: "overlay",
  });
};

export const wkClose = () => {
  // console.log("wk_closed TRIGGERED!!!!");
  logStatsig(TRACKING_EVENTS.wk_closed, wk_metadata);
  ClickServer.track(TRACKING_EVENTS.wk_closed, wk_metadata);
  gtag.event({
    action: TRACKING_EVENTS.wk_closed,
    category: TRACKING_CATEGORIES.interaction,
    label: "overlay",
  });
};

export const wkAdType = () => {
  // console.log("wknd_adtype TRIGGERED!!!!");
  logStatsig(TRACKING_EVENTS.wk_adtype, wk_metadata);
  ClickServer.track(TRACKING_EVENTS.wk_adtype, wk_metadata);
  gtag.event({
    action: TRACKING_EVENTS.wk_adtype,
    category: TRACKING_CATEGORIES.interaction,
    label: "overlay",
  });
};
